.wrapper {
    background: #fff;

    border-radius: 8px;

    padding: 8px;

    width: 100%;
    box-sizing: border-box;
}

.list {
    list-style: none;
    padding-inline-start: 0;
}

.listItem {
    display: grid;
    grid-template-columns: [place] 40px [info] 1fr [visited] 40px;
    padding-bottom: 4px;
}

.listItem:nth-child(odd) {
    background: #ebf0f0;
}

.listItem:nth-child(1) {
    background: gold;
}

.listItem:nth-child(2) {
    background: silver;
}

.listItem:nth-child(3) {
    background: #c49448;
}

.topNumber {
    grid-column-start: place;

    margin: auto;

    font-size: 16px;
    font-weight: 600;
}

.visited {
    grid-column-start: visited;

    margin: auto;

    font-size: 16px;
    font-style: italic;
}

.finish {
    margin-right: 4px;
    padding: 2px 4px;
    font-size: 0.8em;

    color: #fff;

    background-color: #7c7c7c;

    border-radius: 3px;

    opacity: 1;
}

.finish.countdown {
    opacity: 0;

    animation: flickerAnimation 2s infinite;
}

@keyframes flickerAnimation { /* flame pulses */
    0%   { opacity:0; }
    20%  { opacity:1; }
    60%  { opacity:1; }
    100% { opacity:0; }
}