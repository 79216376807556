.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 350px;
    overflow: hidden;

    padding: 0.75em;

    background-color: #f5fafa;
    border-radius: 8px;

    box-sizing: border-box;

    height: 100%;

    margin: 4px 24px 4px auto ;
}

.listWrapper {
    display: block;
    position:relative;

    height: 100%;
}

.listHeader {
    display: grid;

    grid-template-columns: [watch] 50px [info] 1fr;

    padding-bottom: 4px;
}

.listHeader span:nth-child(1) {
    text-align: center;
    font-size: 14px;
    color: blue;
}

.list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    list-style: none;

    padding-inline-start: 0;
    overflow-y: auto;

    margin-right: -28px;
    margin-block-end: 0;
}

.searchWrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 0;
}

.searchInput {
    margin-top: 4px;
    margin-left: 12px;
    margin-right: 12px;

    outline: none;

    border: 1px solid #3251cd;
}

.searchInput:focus {
    outline: none;

    border: 1px solid yellowgreen;
}