.page {
    padding: 8px;
}

.divSelect {
    display: inline-block;
    padding: 8px;

    color: #fff;

    background: green;

    cursor: pointer;

    margin-left: 4px;
}

.divSelect.divSelectOn {
    background: blue;

    cursor: default;
}

.selections {
    display: flex;
    flex-direction: column;

    gap: 8px;
}

.description {
    font-style: italic;
    font-size: 1.1em;

    margin: 8px auto;
}

.rateSelect {
    margin-left: 4px;
}