.btn {
    margin: auto;

    width: 32px;
    height: 32px;

    outline: none;
    border: none;

    background: transparent url(../../images/refresh.png);

    cursor: pointer;
}

.btn:disabled {
    opacity: 0.7;
    cursor: default;
}