.wrapper {
    position: relative;
}

.list {
    display: none;

    position: absolute;
    top: 10px;

    width: 100%;
    box-sizing: border-box;

    border-radius: 4px;

    z-index: 1;

    list-style: none;

    padding: 4px;

    background: #fff;

    transition: opacity;
    transition-duration: 200ms;
    opacity: 0;

    max-height: 400px;
    overflow-y: scroll;
}

.input {
    width: 400px;
    box-sizing: border-box;
}

.listShow {
    display: block;
    opacity: 1;
}

.listFade {
    display: block;
    opacity: 0;
}

.list li {
    cursor: pointer;
    padding: 0 2px;
}

.list li:hover {
    background: #3251cd;
    color: #fff;
}

@media (max-width: 600px) {
    .input {
        width: 100vw;
        padding-left: 4px;
        padding-right: 4px;
    }
}