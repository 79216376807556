.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.canvas {
    display: block;

    border: 1px solid black;
    background-color: #ababfa;
}

.container {
    display: block;

    overflow: hidden;

    position: absolute;
    left: 4px;
    right: 4px;
    top: 28px;
    bottom: 4px;

    cursor: pointer;
}

.map {
    /*display: inline-block;*/

    position: relative;
}

.toolBar {
    display: flex;
    padding: 4px;
}

.toolBar > div {
    margin-right: 12px;
}


@media (pointer: coarse) {
    .container {
        overflow: scroll;
    }
}

@media (max-width: 600px) {
    .toolBar {
        flex-direction: column-reverse;
    }

    .container {
        top: 52px
    }
}
