.App {
    display: grid;

    grid-template-rows: 42px 1fr;
    grid-template-columns: 1fr;

    width: 100vw;
    height: 100vh;
}

.content {
    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 2;
    grid-row-end: 3;

    overflow-y: auto
}

.logo {
    display: block;
    width: 120px;
    height: 50px;

    margin-left: 24px;

    background-image: url(images/logo.png);
    background-repeat: no-repeat;
}

.nav {
    display: flex;

    justify-content: flex-start;

    background-color: #2c2c2c;
    background-image: -webkit-linear-gradient(top, #333333, #222222);
    background-repeat: repeat-x;

    font-size: 16px;
}

.navItem {
    display: inline-block;

    line-height: 24px;
    text-decoration: none;

    color: #999999;

    padding: 8px 12px;
}

.navItem:last-child{
    margin-left: auto;
}

.navItemActive {
    composes: navItem;

    color: #fff;
    background-color: #222222;
}

@media (max-width: 600px) {
    .logo {
        margin-left: 4px;
    }

    .navItem {
        padding: 8px 8px 0 8px;
    }

    .nav {
        font-size: 14px;
    }
}

.settingsBtn {
    display: flex;
    flex-direction: column;
    width: 16px;

    align-items: center;
    justify-content: space-around;

    height: 100%;
}

.settingsBtn > span {
    display: block;
    width: 4px;
    height: 4px;

    background: #555555;
}