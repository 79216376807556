.wrapper {
    font-size: 18px;
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 4px;
}

.text {

}

.time {
    padding-left: 8px;
}
