
.wrapper {
    display: flex;
    flex-direction: column;

    list-style: none;

}

.place_wrapper {
    display: flex;
    gap: 8px;
}

.place {
    width: var(--map-sprite-size);
    height: var(--map-sprite-size);

    background-image: url("../../../images/sprites.png");
    background-position-y: calc(-6 * var(--map-sprite-size));
}

.place_name {
}

.place_human {
    background-position-x: calc(10 * var(--map-sprite-size));
}

.place_elf {
    background-position-x: calc(2 * var(--map-sprite-size));
}

.place_orc {
    background-position-x: calc(6 * var(--map-sprite-size));
    background-position-y: calc(-7 * var(--map-sprite-size));
}

.place_goblin {
    background-position-x: calc(10 * var(--map-sprite-size));
    background-position-y: calc(-7 * var(--map-sprite-size));
}

.place_dwarf {
    background-position-x: calc(6 * var(--map-sprite-size));
}