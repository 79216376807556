.page {
    display: flex;
    flex-direction: column;

    max-width: 800px;

    margin: auto;
    padding: 4px;
}

.label {
    font-size: 1.5em;
    line-height: 2.5em;
}

.checkbox {
    margin-left: 20px;
    box-sizing: border-box;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
}