.box {
    position: relative;

    flex-grow: 1;
}

.value {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-56%, -50%);

    font-size: 1em;
    font-weight: bold;

    color: #fff;
}

.bar {
    width: 90%;
    margin: auto;
    display: block;
    height: 36px;

    background: green;
}

