.item {
    display: grid;

    grid-template-columns: [watch] 50px [info] 1fr;

    padding-bottom: 4px;
}

.heroName {
    font-weight: 600;
}

.description {
    grid-column-start: info;

    font-size: 11px;
}

.watch {
    display: inline-block;

    width: 20px;
    height: 20px;

    border: 1px solid darkblue;

    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: watch;

    margin: auto;

    cursor: pointer;
}

.watchOn::after {
    display: block;
    content: '';

    width: 12px;
    height: 18px;

    box-sizing: border-box;

    border-bottom: 3px solid green;
    border-right: 3px solid green;

    transform: translate(4px, -2px) rotate(45deg);
}