.wrapper {
    display: flex;
    flex-direction: column;
}

.rate {
    display: flex;

    margin-top: 8px;
    gap: 8px;
}

.account {
    display: flex;
    flex-direction: column;

    flex-shrink: 1;

    flex-grow: 0;
    flex-basis: 150px;

    overflow: hidden;

    word-break: keep-all;
    white-space: nowrap;

    text-align: right;
}

.heroName {
    font-weight: 600;
}

.accountName {
    font-size: 11px;
}