.page {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 50px ;

    width: 100%;
    height: 100%;
}

.sign {
    grid-column-start: 1;
    grid-column-end: 3;

    margin: auto;
}

.switcher {
    display: none;

    grid-column-start: 1;
    grid-column-end: 3;

    margin: auto;
}

.switcherBtn {
    width: 80px;
    margin-right: 12px;
    margin-left: 12px;

    padding-top: 4px;
    padding-bottom: 4px;

    color: #0088cc;

    border: 1px solid transparent;
    outline: none;
    background: transparent;

    cursor: pointer;
}

.column1 {
    margin: 4px ;
}

.column2 {
    margin: 4px auto 4px 24px ;

    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-gap: 12px;
}

@media (max-width: 600px) {
    .page {
        grid-template-rows: 30px 1fr 50px ;
        grid-template-columns: 1fr;
    }

    .sign {
        grid-column-end: 2;
    }

    .column1, .column2 {
        margin: 4px auto;
    }

    .column2 {
        display: none;
    }

    .switcher {
        display: block;
    }

    .column2Active .column1 {
        display: none;
    }

    .column2Active .column2 {
        display: flex;

        flex-direction: column;

        gap: 8px;
    }

    .column2Active .switcherBtn2 {
        background: #fff;
        color: #555555;
        border-radius: 2px;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
        cursor: default;
    }

    .column1Active .switcherBtn1 {
        background: #fff;
        color: #555555;
        border-radius: 2px;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
        cursor: default;
    }
}