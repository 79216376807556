.header {

}

.achievement {
    display: inline-block;
    padding: 4px;
    border: 1px solid #2c2c2c;

    border-radius: 4px;

    margin-left: 4px;
}