.mark {
    display: block;
    position: absolute;

    box-sizing: border-box;

    width: 16px;
    height: 32px;

    border-right: 6px #00f solid;
    border-bottom: 6px #00f solid;

    transform: translate(8px, -8px) rotate(45deg);
}