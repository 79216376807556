.button {
    background-color: #5bb75b;
    background-image: -webkit-linear-gradient(top, #62c462, #51a351);
    background-repeat: repeat-x;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-radius: .5rem;

    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

    font-size: 1.15rem;

    padding: 0.4rem 2rem;

    cursor: pointer;
}

.button:hover {
    background-color: #51a351;
    background-position: 0 -15px
}

.button:active:active {
    background-color: #51a351;
    background-image: none;
    outline: 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.button:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.button:disabled {
    background-color: #51a351;
    background-image: none;

    box-shadow: none;

    cursor: default;

    opacity: 0.65;
}
