.hero {
    display: flex;
    flex-direction: column;

    align-items: center;

    position: absolute;

    width: var(--map-sprite-size);
    height: var(--map-sprite-size);

    background-image: url("../../../images/sprites.png");
    background-position-y: calc(-8 * var(--map-sprite-size));

    /*margin-left: calc(var(--map-sprite-size) / 2);*/
    margin-top: -12px;
}

.hasSelected {
    opacity: 0.5;
}

.isSelected {
    opacity: 1;
    z-index: 10;
}

.heroName {
    background: #fff;
    border-radius: 2px;
    border: 1px solid black;
    width: fit-content;

    padding: 0 4px;
    white-space: nowrap;

    margin-top: var(--map-sprite-size);
}

.accountName {

}