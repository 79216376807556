.page {
    display: grid;

    grid-template-rows: 50px 1fr;

    padding: 8px 24px;

    box-sizing: border-box;
}

.nav {

    display: flex;

    gap: 12px;

    padding: 4px 8px;
}

.navItem {
    display: inline-block;

    margin-top: auto;

    box-sizing: border-box;
    height: 40px;

    padding: 0 8px;

    line-height: 36px;

    background-color: #fff;
    color: #2c2c2c;

    border: 1px solid #5c5c5c;
    border-radius: 4px;

    text-decoration: none;
}

.navItemActive {
    background-color: #5bb75b;
    color: #fff;
}