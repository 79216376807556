
.accountDescription dt {
    font-style: italic;
}

.accountDescription dd {
    font-weight: 600;
    font-size: 1.2em;
}

.accountDescription a {
    text-decoration: none;
}

.accountDescription a:hover {
    text-decoration: underline;
}