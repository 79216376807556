.wrapper {
    display: flex;
    flex-direction: column;

    gap: 4px;

    box-sizing: border-box;
}

.achievement {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: 2px solid orangered;

}

.caption {
    padding-top: 4px;
    padding-left: 4px;
    font-size: 1.2em;
    font-weight: bold;
}

.attr {
    font-size: 0.6em;
    font-weight: normal;

    padding: 2px 4px;

    border-radius: 4px;

    color: #fff;

    background: #777;

    margin-left: 8px;
}

.description {
    padding-top: 4px;
    padding-left: 12px;
}

.heroes {
    display: grid;

    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 4px;

    padding: 4px;
}

.hero {
    display: block;

    padding: 4px;
    border-radius: 8px;
    border: 1px solid #777;
}

@media (max-width: 600px) {
    .heroes {
        grid-template-columns: repeat(2, 1fr);
    }
}