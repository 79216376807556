.wrapper {
    position: relative;
    display: flex;

    flex-direction: column;

    cursor: pointer;
}

.heroName {
    font-weight: 600;
}

.description {
    font-size: 11px;
}

.wrapper.banned {
    color: #ccc;
}

.wrapper.finish {
    padding-left: 20px;
}

.finishPlace {
    display: none;
}

.wrapper.finish .finishPlace {
    display: flex;
    flex-direction: column;
    position: absolute;

    top: 50%;
    left: 0;

    transform: translateY(-50%);
}

.time {
    margin-left: 4px;
    padding: 1px 2px;
    font-size: 0.6em;

    color: #fff;

    background-color: #5c5c5c;

    border-radius: 3px;
}

.spinner {
    transform: scale(0.5, 0.5);
}